body,
body[data-theme='light'] {
  --color-bg-toggle: #1266ed;
  --color-text-primary: #f9f9f9;
  --color-text-secondary: #076963;
  --color-text-dark: #000;
  --color-text-light: #838383;
  --color-bg-primary: #f9f9f9;
  --color-bg-header: #151515;
  --color-bg-widget: #ffffff;
  --color-white: #ffffff;
  --color-green: #13806a;
  --color-greenDark: #13806a;
  --color-red: #f25138;
  --color-chartVolumeUp: #719f94;
  --color-chartVolumeDown: #ff9587;
  --color-inActiveSideBarIcon: #666666;
  --color-bg-greenChartGradient: #e6fff8;
  --color-bg-redChartGradient: #ffeae8;
  --color-inverted30: #00000030;
  --color-inverted10: #00000010;
  --color-base2: #eeeeee;
  --color-base1: #f8f8f8;
  --color-blue: #1266ed;
  --color-blue-hover: #1959c1;
  --color-yellow: #ffc90a;
  --color-shade0: #f8f8f8;
  --color-shade1: #eeeeee;
  --color-shade2: #eeeeee;
  --color-shade3: #dddddd;
  --color-shade4: #bbbbbb;
  --filter-img: grayscale(0%);

  /* login */
  --color-account-access-form: #001f1a;
  --color-account-access-form-opacity: rgba(0, 54, 45, 0.6);
  --color-account-access-border: #0c5843;
  --color-account-access-input: #ffffff10;
  --color-account-access-text-white: #fff;
  --color-account-access-text-grey: #8aabaa;

  /* subscription compare */
  --color-darkgreen-white: #00261d;

  /* New Branding Colors */
  --primary-color: black;
  --secondary-color: #75e3dd;
  /* text */
  /* dark greens */
  --darkgreen700: #026c28;
  /* greens */
  --green50: #a2ffa2;
  --green700: #629557;
  --lightGreen: #ebffeb;
  --orange90: #d03c06;
  --orange80: #ff580a;
  --orange60: #ef834c;
  --performance: #ec506d;
  /* greys */
  --grey90: #1a1a1a;
  --grey80: #525b60;
  --grey70: #494949;
  --grey60: #606060;
  --grey50: #8d8d8d;
  --grey30: #bbbbbb;
  --grey20: #d1d1d1;
  /* yellows */
  --yellow100: #feff00;
  --yellow90: #feff6d;
  --yellow80: #fffe38;
  --yellow50: #fffe71;
  --yellow30: #fffe8d;
  --yellow10: #fffec6;
  --yellowGrey: #e8e870;
  --yellowStatusBar: #ffff00;

  /* purples */
  --purple90: #6b78ff;
  --purple70: #e8e9f7;
  --purple60: #9f72ff;
  --purpleOpacity30: #8a94ff30;

  /* peaches */
  --peach90: #ff7941;
  --peach80: #ff8756;
  --peach50: #ff966b;
  --peach30: #ffb495;
  --peach10: #ffd2bf;

  /* cyan */
  --cyan100: #015f59;
  --cyan90: #21bbb2;
  --cyan60: #75e3dd;
  --cyan40: #7ee3de;
  --cyan30: #91ddd9;
  --cyan20: #b8e4e2;
  --cyan10: #cbedeb;
  --cyanOpacity80: #cbedeb80;
  --cyanStatusBar: #00dcd0;
}

body[data-theme='dark'] {
  --color-bg-toggle: #a9a9a9;
  --color-text-primary: #111111;
  --color-text-secondary: #ff6b00;
  --color-text-dark: #fff;
  --color-text-light: #bbbbbb;
  --color-bg-primary: #111111;
  --color-bg-header: #151515;
  --color-bg-widget: #1a1a1a;
  --color-white: #ffffff;
  --color-green: #13806a;
  --color-greenDark: #13806a;
  --color-red: #ff7081;
  --color-chartVolumeUp: #719f94;
  --color-chartVolumeDown: #ff9587;
  --color-inActiveSideBarIcon: #888888;
  --color-bg-greenChartGradient: #102e25;
  --color-bg-redChartGradient: #471811;
  --color-inverted30: #ffffff30;
  --color-inverted10: #ffffff10;
  --color-base2: #222222;
  --color-base1: #1a1a1a;
  --color-blue: #1266ed;
  --color-blue-hover: #1959c1;
  --color-yellow: #ffc90a;
  --color-shade0: #1a1a1a;
  --color-shade1: #222222;
  --color-shade2: #333333;
  --color-shade3: #555555;
  --color-shade4: #888888;
  --filter-img: grayscale(100%);

  /* login */
  --color-account-access-form: #001f1a;
  --color-account-access-form-opacity: rgba(0, 54, 45, 0.6);
  --color-account-access-border: #0c5843;
  --color-account-access-input: #ffffff10;
  --color-account-access-text-white: #fff;
  --color-account-access-text-grey: #8aabaa;

  /* subscription compare */
  --color-darkgreen-white: #ffffff;
}

* {
  transition: all 0.05s ease-in-out;
}

body {
  background: var(--color-bg-primary) !important;
  color: var(--color-text-primary) !important;
}

html,
html[lang='en'] {
  --lang-bold: 'DM Sans';
  --lang-regular: 'DM Sans';
  --headers-regular: 'RecoletaAlt-regular';
  --headers-bold: 'RecoletaAlt-bold';
}

html,
html[lang='ar'] {
  --lang-bold: 'Tajawal';
  --lang-regular: 'Tajawal';
}

.ant-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}


