@use 'sass:math';

.loading {
  padding: 0px;
  width: 90px;
  height: 70px;
}
@keyframes loading {
  0% {
    background-color: #eee;
  }

  30% {
    background-color: #eee;
  }
  50% {
    height: 60px;
    margin-top: 0px;
  }
  80% {
    background-color: #eee;
  }
  100% {
    background-color: #eee;
  }
}
@mixin inner() {
  height: 10px;
  width: 20%;
  margin-right: 2px;
  margin-left: 2px;
  display: inline-block;
  margin-top: 50px;
  -webkit-animation: loading 1s infinite;
  -moz-animation: loading 1s infinite;
  -o-animation: loading 1s infinite;
  animation: loading 1s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
@mixin loading() {
  @for $i from 1 through 10 {
    .loading-#{$i} {
      @include inner();
      -webkit-animation-delay: math.div($i, 7) + s;
      animation-delay: math.div($i, 7) + s;
    }
  }
}
.loading {
  @include loading();
}
